import styled, { css } from "styled-components";
import { color } from "../../../atoms/colors";
import { font } from "../../../atoms/typography";

export const SlideboxWrap = styled.div`
  max-width: 1230px;
  margin: 0 auto;
`

export const SlideboxContent = styled.div`
  padding: 32px 30px 48px;
  @media (min-width: 577px) {
    padding: 56px 30px 64px;
  }
  @media (min-width: 993px) {
    padding: 64px 30px 96px;
  }
  ${({ noBottomPadding }) => css`
    padding-bottom: ${noBottomPadding === true && 0};
    @media (min-width: 577px) {
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (min-width: 993px) {
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const SlideboxNavWrap = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -30px 32px;
  @media (min-width: 577px) {
    margin: 0 -30px 40px;
  }
  @media (min-width: 993px) {
    margin: 0 0 40px;
    justify-content: space-evenly;
    overflow-x: unset;
  }
`

export const SlideNavItem = styled.div`
  ${font('text', 'reg', '600')}
  padding: 0 16px 12px;
  @media (min-width: 993px) {
    ${font('display', 'xxs', '600')}
    padding: 0 24px 16px;
  }
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 8px;
  transition: color 250ms ease;
  border-bottom: 4px solid ${color.grey[300]};
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  color: ${color.grey[600]};
  .polygon {
    position: absolute;
    left: 0;
    path {
      transition: fill 250ms ease;
      fill: ${color.grey[300]};
    }
  }
  ${({ active }) => active && css`
    border-bottom: 4px solid ${color.common.workgridBlue};
    color: ${color.common.black};
    .polygon {
      path {
        fill: ${color.common.hoverBlue};
      }
    }
  `}
`
