import React from "react";

import { BorderIcon } from "./styles/Icon.styled";

const Icon = ({ id, size = 24, active, pointer, classes }) => (
  <BorderIcon
    active={active}
    pointer={pointer}
    width={size}
    height={size}
    className={classes}
  >
    <use href={`/sprites/sprites.svg#${id}`} />
  </BorderIcon>
)

export default Icon