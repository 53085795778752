import React, { useState } from "react";
import Heading from "../Heading";
import Slide from "./components/Slide";
import { SlideboxWrap, SlideboxNavWrap, SlideboxContent, SlideNavItem } from "./styles/Slidebox.styled";
import onKeyDown from "../../utils/onKeyDown";
import useScreenResponsive from "../../utils/screenResponsive";
import Icon from "../Icon";

const Slidebox = ({ component }) => {
  const isMobile = useScreenResponsive(576)
  const initialActiveNav = component?.slides[0]?.navTitle ?? component?.slides[0]?.heading
  const heading = { ...component?.slideboxHeading, noTopPadding: component?.noTopPadding }
  const slides = component?.slides

  const [active, setActive] = useState(initialActiveNav)

  return (
    <SlideboxWrap>
      {heading && <Heading component={heading} />}
      <SlideboxContent noBottomPadding={component?.noBottomPadding}>
        <SlideboxNavWrap>
          {slides?.map((slide) => {
            const navTitle = slide?.navTitle ?? slide?.heading
            return (
              <SlideNavItem
                key={navTitle}
                active={navTitle === active}
                onClick={() => setActive(navTitle)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => onKeyDown(e, () => setActive(navTitle))}
                aria-label={navTitle}
                aria-current={navTitle === active}
              >
                {slide?.iconIdSelect && !isMobile &&
                  <Icon active={navTitle === active} id={slide.iconIdSelect} classes={navTitle === active && 'active'} size={46} />
                }
                {navTitle}
              </SlideNavItem>
            )
          }
          )}
        </SlideboxNavWrap>
        {slides?.map((slide, index) => (
          <Slide slide={slide} key={index} active={active === (slide?.navTitle ?? slide?.heading)} />
        ))}
      </SlideboxContent>
    </SlideboxWrap>
  )
}

export default Slidebox
