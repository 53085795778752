import styled, { css } from "styled-components"
import { color } from "../../../atoms/colors"

export const BorderIcon = styled.svg`
  use {
    transition: fill ease 350ms, stroke ease 350ms, color ease 350ms;
    ${({ width }) => width && css`
      width: ${width}px;
    `}
    ${({ height }) => height && css`
      width: ${height}px;
    `}
    fill: ${color.grey[300]};
  }
  &.active {
    use {
      fill: ${color.common.hoverBlue};
    }
  }
`