import React from "react"
import {
  ArrowLink,
  Body,
  Heading,
  ImageSide,
  ImgWrap,
  Kicker,
  SlideWrap,
  TextSide,
  VideoWrap,
} from "../styles/Slide.styled"
import OptimizedImage from "../../../utils/optimizedImage"
import { ContentfulVideo } from "../../ContentfulVideo/ContentfulVideo"
import { switchbackResolveRichText } from "../../../utils/resolve-rich-text/switchbackResolveRichText"
import { LottieWrapper } from "../../Lottie/LottieWrapper"
import { Chevron } from "../../../utils/IconsList"

const Slide = ({ slide, active }) => {
  const lottie = slide?.lottie && slide?.lottie?.file?.url?.includes("json")
  const copy = slide?.subheading ?? slide?.bodyCopy
  const cta = slide?.callsToAction?.length > 0 && slide?.callsToAction[0]
  const hasAsset = slide?.featuredImage || slide?.video || slide?.lottie

  return (
    <>
      {active && (
        <SlideWrap>
          <TextSide>
            <Kicker>{slide?.kicker}</Kicker>
            <Heading>{slide?.heading}</Heading>
            {copy && (
              <Body>{switchbackResolveRichText(copy, "check-line")}</Body>
            )}
            {cta && (
              <ArrowLink to={cta?.link}>
                {cta?.label}
                <Chevron />
              </ArrowLink>
            )}
          </TextSide>
          {hasAsset && (
            <ImageSide>
              {slide?.featuredImage && (
                <ImgWrap>
                  <OptimizedImage
                    image={slide?.featuredImage?.gatsbyImageData}
                    src={slide?.featuredImage?.file?.url}
                    alt={slide?.featuredImage?.description || "featured image"}
                  />
                </ImgWrap>
              )}
              {slide?.video && (
                <VideoWrap>
                  <ContentfulVideo videoData={slide?.video} />
                </VideoWrap>
              )}
              {lottie && (
                <LottieWrapper imageAnimation={slide?.lottie?.file?.url} />
              )}
            </ImageSide>
          )}
        </SlideWrap>
      )}
    </>
  )
}

export default Slide
