import styled from "styled-components";
import { font } from "../../../atoms/typography";
import { color } from "../../../atoms/colors";
import { Link } from "gatsby";

export const SlideWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  row-gap: 32px;
  @media (min-width: 577px) {
    row-gap: 40px;
  }
  @media (min-width: 993px) {
    grid-template-columns: 43fr 48fr;
    column-gap: 9%;
    align-items: center;
  }
`

export const TextSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`

export const Kicker = styled.p`
  ${font('kicker', 'reg', '900')}
  color: ${color.common.workgridBlue};
`

export const Heading = styled.h3`
  color: ${color.common.black};
  ${font('display', 'xs', '700')}
  @media (min-width: 577px) {
    ${font('display', 'sm', '700')}
  }
  @media (min-width: 993px) {
    ${font('display', 'md', '700')}
  }
`

export const Body = styled.div`
  ${font('text', 'md', '400')}
  color: ${color.grey[600]};
`

export const ArrowLink = styled(Link)`
  ${font('text', 'reg', '700')}
  color: ${color.common.workgridBlue};
  text-decoration: none;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    transform: rotate(90deg);
  }
  :hover {
    text-decoration: underline;
  }
`

export const ImageSide = styled.div`
  width: 100%;
  img {
    max-width: 100%;
  }
  .gatsby-image-wrapper {
    max-width: 100%;
  }
  @media (min-width: 577px) {
    img {
      max-width: 506px;
    }
    .gatsby-image-wrapper {
      max-width: 506px
    }
  }
  @media (min-width: 768px) {
    img {
      max-width: 576px;
    }
    .gatsby-image-wrapper {
      max-width: 576px;
    }
  }
  @media (min-width: 993px) {
    max-width: 100%;
    img {
      max-width: 100%;
    }
  }
`

export const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const VideoWrap = styled.div`
  div > div  {
    aspect-ratio: 16 / 9 !important;
    width: 100%!important;
    height: unset!important;
  }
`
